<template>
  <router-view :key="$route.path"></router-view>
</template>
<script>
export default {
  components: {},
  data: () => ({}),
  computed: {},

  methods: {},
  mounted() {},
};
</script>

<style scoped>
</style>